import React from "react";
import Layout from "@/components/Layout";
import { StaticImage } from "gatsby-plugin-image";
import Teammates from "@/components/Carts/Teammates";
import { graphql } from "gatsby";
import { KeystoneImage } from "@/components/gatsby-image-keystone";

export default function AboutUs({ data }: any) {
  const components = data.cms.components;

  const teammates = components.map((e: any) => {
    return (
      <div
        data-aos="fade-up"
        data-aos-duration="3000"
        key={components.indexOf(e)}
        // className="hover:-translate-y-[10px] text-center max-w-[150px] duration-300 ease-in-out mx-[7px] mb-[3rem] inline-block"
        className="hover:-translate-y-[10px] text-center duration-300 ease-in-out mb-[3rem] inline-block mx-auto"
      >
        <div className="max-w-[150px] rounded-full overflow-hidden min-w-[150px] mx-auto">
          {e.featureImage && (
            <KeystoneImage
              baseUrl="steppelink"
              image={{
                alt: e.featureImage.id,
                width: 150,
                height: 150,
                key: `${e.featureImage.id}.${e.featureImage.extension}`,
              }}
              layout="fullWidth"
              alt={e.featureImage.id}
            />
          )}
        </div>
        <div className="mt-[2rem] text-center">
          <span>
            <p className="lg:text-[14px] mx-auto md:w-max font-[600]">
              {e.title}
            </p>
            <p className="lg:text-[14px] max-w-[200px] mx-auto text-center font-[200] text-grayLight">
              {e.content.document[0].children[0].text}
            </p>
          </span>
        </div>
      </div>
    );
  });

  return (
    <Layout title="About us">
      <div className="">
        <div className="relative lg:container z-30 px-[20px] lg:px-0">
          <h3
            data-aos="fade-up"
            className="text-center max-w-[900px] mx-auto md:text-[22px]"
          >
            STEPPE LINK LLC has been operating continuously for more than 9
            years since its establishment on January 29, 2013.
          </h3>
        </div>

        <div className="pb-[8rem] relative z-30 mt-[4rem]">
          <div className="mb-[2rem] lg:container relative z-30 px-[20px] lg:px-0">
            <h1
              data-aos="fade-up"
              className="mb-[1rem] text-center md:mb-[2rem]"
            >
              Our Mission
            </h1>
            <p
              data-aos="fade-up"
              className="mx-auto mb-[5rem] w-[80%] text-center text-[18px] md:text-[22px]"
            >
              {" "}
              We will be your adequate partner in the fast changing digital era.
              We will help you to optimize your business and lead digital
              transformation together by sharing our +10 years of expertise with
              empathic and agile support.
            </p>
            <h1
              data-aos="fade-up"
              className="mb-[1rem] text-center md:mb-[2rem]"
            >
              Our Core Value
            </h1>
            <p
              data-aos="fade-up"
              className="mx-auto w-[80%] text-center text-[18px] md:text-[22px]"
            >
              {" "}
              We want to build a team full of synergistic chemistry in...
            </p>
          </div>

          <div className="absolute -right-[25%] top-[6rem] z-0">
            <StaticImage
              src="../../static/about/robot.png"
              placeholder="blurred"
              alt=""
              className="md:opacity-100 opacity-50"
              width={900}
            />
          </div>

          <div
            data-aos="fade-up"
            className="grid sm:grid-cols-2 lg:grid-cols-4 relative text-center z-30 gap-[1rem] lg:container px-[20px] lg:px-0"
          >
            <div className="bg-orange p-[2rem] border-[2px] rounded-xl max-w-[250px] mx-auto min-h-[200px]">
              <p className="font-[600] mb-[1rem] text-white">Big Oxytocin</p>
              <p className="lg:text-[16px] text-white">
                We are brothers and sisters, family and soulmates who love to
                understand and support each other with kindness.
              </p>
            </div>
            <div className="dark:bg-theme bg-white p-[2rem] border-[2px] rounded-xl max-w-[250px] mx-auto min-h-[200px]">
              <p className="font-[600] mb-[1rem] dark:text-white">
                Big Dopamine
              </p>
              <p className="lg:text-[16px] dark:text-white">
                We are hungry for success and growth brought by our own
                knowledge and effort.
              </p>
            </div>
            <div className="bg-orange p-[2rem] border-[2px] rounded-xl max-w-[250px] mx-auto min-h-[200px]">
              <p className="font-[600] mb-[1rem] text-white">Big Endorphine</p>
              <p className="lg:text-[16px] text-white">
                We are passionate about what we are doing and love to challenge
                ourselves.
              </p>
            </div>
            <div className="dark:bg-theme bg-white p-[2rem] border-[2px] rounded-xl max-w-[250px] mx-auto min-h-[200px]">
              <p className="font-[600] mb-[1rem] dark:text-white">
                Big Serotonin
              </p>
              <p className="lg:text-[16px] dark:text-white">
                We love to take risks and exceed our limitations with equanimity
                and enthusiasm.
              </p>
            </div>
          </div>
        </div>

        <div className="relative z-30 lg:container px-[20px] lg:px-0">
          <h1 data-aos="fade-up" className="mb-[2rem] text-center md:text-left">
            Our Team
          </h1>
          <div className="grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 grid-cols-2">
            {teammates}
          </div>
          {/* <Teammates /> */}
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query AboutUsPageQuery {
    cms {
      components(orderBy: { order: asc }) {
        content {
          document
        }
        title
        featureImage {
          extension
          filesize
          height
          id
          url
          width
        }
      }
    }
  }
`;
